<template>
	<div class="pa-3">
		<FieldGroup v-if="ready" v-model="item" :fields="fields" :split="2" mode="edition" :pevents="events" :path="path" />
		<div v-else class="pa-8 text-center">
			<v-progress-circular indeterminate size="40" color="primary"/>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Form",
		components: {
			FieldGroup: () => import('@/components/FieldGroup.vue')
		},
		data(){
			return {
				item: {},
				fields: {},
				path: this.$route.path.replace('/dashboard/', ''),
				events: null,
				ready: false
			}
		},
		methods: {
			getFields(){
				this.$wsc.getOptions(this.$route.params, {}, this.path, success => {
					this.fields = success.fields
					this.getItem()
				}, fail => {

				})
			},
			getItem(){
				this.$wsc.getItem(this.path, '', success => {
					this.item = success
					this.ready = true
				}, fail => {

				})
			},
			setUpHooks(){
				this.events = new this.$NVEvent('Form')
			}
		},
		created() {
			this.getFields()
			this.setUpHooks()
		}
	}
</script>

<style scoped>

</style>